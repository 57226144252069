<script setup>
  import { computed } from '@vue/reactivity'
  import { useRoute } from "vue-router";

  import PriceListsHeader from './PriceListsHeader.vue';
  import useGetPricelists from '../../../hooks/price-lists/useGetPricelists';
  import PriceListsList from './PriceListsList.vue';
  import InlinePreloader from '../../../components/parts/inlinePreloader.vue';

  const route = useRoute();
  const routeQuery = computed(() => route.query);
  const { data, isLoading, isFetching, isRefetching } = useGetPricelists(routeQuery)
</script>

<template>
  <!-- <PriceListsHeader></PriceListsHeader>
  <InlinePreloader v-if="isLoading" message="Loading..."></InlinePreloader>
  <PriceListsList v-else :price-lists="data" :class="{'tw-animate-pulse': isRefetching}"></PriceListsList> -->
  <router-view></router-view>
</template>

<style scoped>

</style>
